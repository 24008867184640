<template>
  <div class="contract-details-container">
    <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
    <div class="cm-flex-container">
      <h2 class="page-header">
            {{ "contract_management" }}
      </h2>
      <div class="button-container" v-if="(loggedUserHierarchyLevel === 3)">
        <Button class="btn btn-primary cntrct-btn" :label="$t('Print Interim Invoice')" :color="'primary'" @click="printInterimInvoice()"/>
        <Button class="btn btn-secondary cntrct-btn" :label="$t('Manual Verification Done')" :color="'secondary'" @click="manuallyVerify()" :disabled="!manualVerificationCompleted" />
      </div>
    </div>
    <div id="tab_section">
        <Tabs
            ref="tabs"
            :tabs="tabs"
            colorTheme="light"
        />
    </div>
    <div>
    </div>
    </div>
</template>

<script>
import Button from '../../../shared/components/Button.vue'
import Breadcrumbs from '../../../shared/components/Breadcrumbs.vue'
import Tabs from '@/app/shared/components/Tabs.vue'
import { mapState, mapActions } from 'vuex'
// import moment from 'moment'

export default {
  name: 'ContractDetails',
  data: () => ({
    id: 0,
    reportingUnitIdList: [],
    tabs: [
      {
        tab_title: 'Contract Details',
        to_link: '/dashboard/contract/:id/details',
        alternate_title: 'ContractTabBulk'
      },
      {
        tab_title: 'KPI Details',
        to_link: '/dashboard/contract/:id/kpis',
        alternate_title: 'KPITabBulk'
      },
      {
        tab_title: 'Targeted Details',
        to_link: '/dashboard/contract/:id/targetedDetails',
        alternate_title: 'ContractTab'
      },
      {
        tab_title: 'Invoices',
        to_link: '/dashboard/contract/:id/invoice',
        alternate_title: 'InvoiceTab'
      }
    ],
    breadcrumbs: [
      {
        label: 'Dashboard',
        link: '/dashboard/overview',
        class: ''
      },
      {
        label: '_contracts',
        link: '',
        class: 'active'
      }
    ]
  }),
  methods: {
    fetchInvoice (interimInvoice = true, generateInvoiceIfNotExists = true, downloadPdf = true, updateInvoiceData = false) {
      this.fetchInvoicePdf({ reportingUnitId: this.id, interimInvoice, generateInvoiceIfNotExists, downloadPdf })
      if (updateInvoiceData) {
        window.location.reload()
      }
    },
    printInterimInvoice () {
      return this.fetchInvoice(true, true, true)
    },
    manuallyVerify () {
      return this.fetchInvoice(false, true, false, true)
    },
    ...mapActions('ContractManagement', ['setReportingUnitId', 'fetchUserSummary', 'fetchInvoicePdf', 'fetchManualVerificationData'])
  },
  mounted () {
    this.id = this.$route.params.id
    this.tabs.forEach(tab => {
      tab.to_link = tab.to_link.replace(':id', this.id)
    })
    this.setReportingUnitId(this.id)
    this.fetchManualVerificationData({ reportingUnitId: this.id })
    this.fetchUserSummary()
  },
  components: {
    Breadcrumbs,
    Button,
    Tabs
  },
  computed: {
    ...mapState('ContractManagement', ['reportingUnitId', 'allowContractEdit', 'loggedUserHierarchyLevel', 'manualVerificationCompleted'])
  }
}
</script>

<style>

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.nav-tabs>li>a {
    margin-right: 2px;
    margin-left: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}
.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px;
}
.cntrct-btn {
  margin-left: 10px;
}
.manual-verification-table
.table {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
}

.cm-flex-container {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  width: 99%;
}

.cm-select-container {
  flex: 75;
  display: flex;
  align-items: center;
  padding-left: 25%;
}

.cm-btn-align-right {
  text-align: right;
  flex: 25;
  margin-right: 15px;
}

</style>
